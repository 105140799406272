// pusherEvents
// This file defines events coming from Pusher channels

// Built-in Pusher Events
export const SUBSCRIPTION_SUCCEEDED = 'pusher:subscription_succeeded';
export const SUBSCRIPTION_ERROR = 'pusher:subscription_error';

// Server Events
export const EVENTS = 'events';
export const CHAT_MESSAGE_PUSHED = 'chat.message-pushed';
export const CART_CHECKOUT = 'cart.checkout';
export const PURCHASE_SUCCESSFUL = 'purchase.successful';
export const PURCHASE_FAILED = 'purchase.failed';
export const QUEST_DAILY_LOGIN = 'quest.dailylogin';
export const BALANCE_UPDATED = 'balance.updated';
export const NOTIFICATION = 'notification';
export const NOTIFICATION_READ = 'notification.read';
export const CONFIGURATION_UPDATED = 'configuration.updated';
export const USER_REPLY_PRICE_CHANGED = 'user.reply-price-changed';
export const STREAM_AUTHORIZED = 'stream.authorized';
export const STREAM_ONLINE = 'stream.online';
export const STREAM_OFFLINE = 'stream.offline';
export const SOURCE_ONLINE = 'source.online';
export const SOURCE_OFFLINE = 'source.offline';
export const DEVICE_ONLINE = 'device.online';
export const DEVICE_OFFLINE = 'device.offline';
export const STREAM_VIEWERS_UPDATED = 'stream.viewers.updated';
export const CHAT_MESSAGE_SENT = 'chat.message.sent';
export const CHAT_USER_ENTERED = 'chat.user.entered';
export const GIFT_SENT = 'gift.sent';

export const GIFT_PRELOAD_TRIGGERED = 'gift.preload.triggered';
export const STREAM_PAYMENT_FAILED = 'stream.payment.failed';
export const STREAM_REVENUE_UPDATED = 'stream.revenue.updated';
export const GOAL_ADDED = 'goal.added';
export const GOAL_UPDATED = 'goal.updated';
export const GOAL_METADATA_UPDATED = 'goal.metadata.updated';
export const GOAL_PROGRESS_UPDATED = 'goal.progress.updated';
export const GOAL_STARTED = 'goal.started';
export const GOAL_ENDED = 'goal.ended';
export const MESSAGE_SENT = 'message.sent';
export const COUNTER_ADDED = 'counter.added';

// User
export const USER_STATUS_ONLINE = 'user.online';
export const USER_STATUS_OFFLINE = 'user.offline';
export const USER_PERMISSIONS_UPDATED = 'user.permissions.updated';
export const ACCOUNT_CONNECTED = 'account.connected';

// transcoding status
export const UPLOAD_COMPLETED = 'upload.completed';
export const UPLOAD_FAILED = 'upload.failed';
export const PROCESSING_STARTED = 'processing.started';
export const PROCESSING_FAILED = 'processing.failed';
export const PROCESSING_COMPLETED = 'processing.completed';
export const REVIEW_STARTED = 'review.started';
export const REVIEW_COMPLETED = 'review.completed';
export const REVIEW_FAILED = 'review.failed';
export const DELIVERY_STARTED = 'delivery.started';
export const DELIVERY_COMPLETED = 'delivery.completed';
export const DELIVERY_FAILED = 'delivery.failed';

// Leaker Protection
export const FPJS_ENABLE = 'fpjs.enable';
export const DISTURBING_ENABLE = 'disturbing.enable';
export const DISTURBING_DISABLE = 'disturbing.disable';

// campaign
export const CAMPAIGN_LEADERBOARD_UPDATED = 'campaign.leaderboard.updated';

// User level
export const LEVEL_UPDATED = 'level.updated';
export const FEED_UPDATED = 'feed.updated';
export const SPENDINGS_UPDATED = 'spendings.updated';

// Kyc
export const KYC_COMPLETED = 'kyc.completed';
export const KYC_FAILED = 'kyc.failed';
export const KYC_STARTED = 'kyc.started';

// Asset
export const ASSET_COMPLETED = 'asset.completed';
export const ASSET_FAILED = 'asset.failed';
export const ARTIFACT_UPLOADED = 'artifact.uploaded';
export const ARTIFACT_FAILED = 'artifact.failed';

export const CREATOR_APPLICATION_APPROVED = 'creator.application.approved';

export const SUBSCRIPTION_CANCELLED = 'subscription.cancelled';

export const ORDER_CREATED = 'order.created';
export const ORDER_PAID = 'order.paid';
export const ORDER_FULFILLED = 'order.fulfilled';
export const ORDER_CANCELLED = 'order.cancelled';
export const ORDER_FAILED = 'order.failed';

// Sic bo
export const SIC_BO_CREATED = 'sic_bo.created';
export const SIC_BO_ENDED = 'sic_bo.ended';
