// sendCurrentRemoteConfig.js
'use strict';
import getOperationData from '../selector/getOperationData.js';
import getMeData from '../selector/getMeData.js';
import trackMixpanel, { EventTypes } from '../resource/mixpanel.js';

/**
 * Send current remote config
 * @kind action
 * @return {Promise} Action promise.
 */
const sendCurrentRemoteConfig = () => async (dispatch, getState) => {
  const configRawData = getOperationData(getState(), ['config']);
  if (configRawData?.configurationData) {
    // Remove default config to avoid too many keys
    delete configRawData.configurationData.default;
  }

  const abRawData = getMeData(getState(), 'ab');
  if (abRawData) {
    Object.values(abRawData).forEach(ab => {
      delete ab.abToken;
      delete ab.exp;
    });
  }

  trackMixpanel({
    type: EventTypes.USER_DEBUG_SENT,
    payload: {
      configRawData,
      abRawData,
    },
  });
};

export default sendCurrentRemoteConfig;
