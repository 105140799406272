// LanguageButton.js
import { connect } from 'react-redux';

import LanguageButton from '../component/LanguageButton.jsx';

import updateLanguage from '../action/updateLanguage.js';
import toggleModal from '../action/toggleModal.js';

import getModalData from '../selector/getModalData.js';

const mapStateToProps = state => {
  return {
    isLanguageMenuOpened: ['opening', 'display'].includes(
      getModalData(state, 'LanguageMenu', 'status')
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateLanguage: ({ language }) => dispatch(updateLanguage({ language })),
    toggleModal: payload => dispatch(toggleModal(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageButton);
