// useBecomeCreatorLink.js
import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { JWT_SCOPE_CREATOR, SWAG_BECOME_CREATOR } from '../RemoteConfigKeys.js';

import getMeData from '../selector/getMeData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getIsPermissionMatched from '../selector/getIsPermissionMatched.js';
import getIsCpOnboarding from '../selector/getIsCpOnboarding.js';
import getOperationData from '../selector/getOperationData.js';

import fetchAccountLinks from '../action/fetchAccountLinks.js';
import fetchMeCreator from '../action/fetchMeCreator.js';

import { getIsInternalLink } from '../resource/getInternalLink.js';

import { KYC_FAILED } from '../serviceWorker/PusherEvents.js';

/**
 * Use become creator link
 * @kind hook
 * @param {[shouldHideIsCreator = true]} - should hide link if is creator.
 * @return {undefined|object} Return object to render become creator link
 */
const useBecomeCreatorLink = ({ shouldHideIsCreator = true } = {}) => {
  const meId = useSelector(state => getMeData(state, 'id'));
  const isAccountLinksFetching = useSelector(state =>
    getNetworkingData(state, ['me', 'accountLinks'], 'isFetching')
  );
  const isAccountLinksFetched = useSelector(state =>
    getNetworkingData(state, ['me', 'accountLinks'], 'isFetched')
  );
  const isMeCreatorFetching = useSelector(state =>
    getNetworkingData(state, ['fetch', 'me', 'creator'], 'isFetching')
  );
  const isMeCreatorFetched = useSelector(state =>
    getNetworkingData(state, ['fetch', 'me', 'creator'], 'isFetched')
  );
  const isCreator = useSelector(state =>
    getIsPermissionMatched(state, JWT_SCOPE_CREATOR)
  );
  const kycStatus = useSelector(state =>
    getOperationData(state, ['join', 'kyc'], 'status')
  );
  const isCreatorOnboarding =
    useSelector(state => getIsCpOnboarding(state)) || KYC_FAILED === kycStatus;
  const becomeCreatorUrl = useSelector(state =>
    getRemoteConfigData(state, SWAG_BECOME_CREATOR)
  );
  const dispatch = useDispatch();
  // Fetch account links
  useEffect(() => {
    if (meId && !isAccountLinksFetching && !isAccountLinksFetched) {
      dispatch(fetchAccountLinks());
    }
  }, [dispatch, isAccountLinksFetched, isAccountLinksFetching, meId]);
  // Fetch me creator
  useEffect(() => {
    if (meId && !isMeCreatorFetching && !isMeCreatorFetched) {
      dispatch(fetchMeCreator());
    }
  }, [dispatch, isMeCreatorFetched, isMeCreatorFetching, meId]);
  const result = useMemo(() => {
    if (isCreator && shouldHideIsCreator) {
      return;
    }
    if (isCreatorOnboarding) {
      return {
        isInternalLink: true,
        link: '/join',
      };
    }
    if (!becomeCreatorUrl) {
      return;
    }
    const isBecomeCreatorUrlInternal = getIsInternalLink({
      link: becomeCreatorUrl,
    });
    return {
      isInternalLink: isBecomeCreatorUrlInternal,
      link: becomeCreatorUrl,
    };
  }, [becomeCreatorUrl, isCreator, isCreatorOnboarding, shouldHideIsCreator]);
  return result;
};

export default useBecomeCreatorLink;
