// getJoinProgress.js
'use strict';
import { createSelector } from '../resource/createCachedSelector.js';

import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';

import {
  KYC_COMPLETED,
  KYC_FAILED,
  KYC_STARTED,
} from '../serviceWorker/PusherEvents.js';

import { Progress as JoinProgress } from '../resource/joinConstants.js';

const defaultObject = {};

/**
 * Select join progress.
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {JoinProgress} Return current join progress.
 */
const getJoinProgress = createSelector(
  state => getMeData(state, 'creator'),
  state => getMeData(state, 'username'),
  state => getMeData(state, 'accountLinks'),
  state => getOperationData(state, ['join', 'kyc'], 'status'),
  (
    creator = defaultObject,
    username,
    accountLinkObject = defaultObject,
    kycStatus
  ) => {
    const { gender, country, kycId, isApproved } = creator;
    const email = accountLinkObject?.email?.id;
    if (!email || !username || !gender || !country) {
      return JoinProgress.PERSONAL_INFORMATION;
    }

    if (!kycId) {
      if (kycStatus === KYC_FAILED) {
        return JoinProgress.PERSONAL_INFORMATION;
      }
      if (kycStatus === KYC_COMPLETED) {
        return JoinProgress.OCR_RESULT;
      }
      if (kycStatus === KYC_STARTED) {
        return JoinProgress.OCR_PROCESSING;
      }
      return JoinProgress.UPLOAD_IDENTIFICATION;
    }
    if (!isApproved) {
      return JoinProgress.MANUAL_REVIEW;
    }
    return JoinProgress.BECOME_SWAGGER;
  }
);

export default getJoinProgress;
