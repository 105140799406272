// pusherUtils.js
'use strict';

export const getPrivateUserChannel = ({ userId }) =>
  userId ? `private-enc-user@${userId}` : undefined;

const CRITICAL_CHANNEL_REGEXES = [
  /^presence-enc-client@.+/,
  /^presence-enc-user@.+/,
  /^private-enc-stream@([a-z\d]+)$/i,
  // presence-enc-stream-viewer@STREAMER_ID.PRESET.VIEWER_ID
  /^presence-enc-stream-viewer@([a-z\d]+)\.([a-z]+)\.([a-z\d]+)$/i,
  /^presence-enc-asset@([a-z\d]+)$/i,
  /^presence-enc-message@([a-z\d]+)$/i,
  /^private-enc-campaign@([a-z\d_]+)$/i,
];

/**
 * Determines if a given channel is considered critical.
 *
 * @param {object} options - The options object.
 * @param {string} options.channelName - The name of the channel to check.
 * @returns {boolean} True if the channel is critical, false otherwise.
 */
export const getIsCriticalChannel = ({ channelName }) =>
  !!CRITICAL_CHANNEL_REGEXES.find(regex => regex.test(channelName));
