// fetchMeCreator.js
'use strict';
import fetch from '../resource/customFetch.js';
import mergeMeData from '../action/mergeMeData.js';

import getMeData from '../selector/getMeData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';

import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch me creator
 * @kind action
 * @return {Promise} Action promise.
 */
const fetchMeCreator = () => async (dispatch, getState) => {
  const selectPath = ['fetch', 'me', 'creator'];
  const token = getMeData(getState(), 'token');
  const isFetching = getNetworkingData(getState(), selectPath, 'isFetching');
  if (!token || isFetching) {
    return dispatch({ type: '' });
  }
  const fetchOptions = {
    method: 'GET',
    headers: {
      ...getHeaders(),
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchUrl = getResourceUrl({ endpoint: '/me/creator' });
  dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
  try {
    let response = await fetch(fetchUrl.href, fetchOptions);
    if (!response.ok) {
      response = await handleFetchError({
        response,
        dispatch,
        getState,
        fetchOptions,
        fetchUrl,
      });
    }
    const payload = await response.json();
    dispatch(
      mergeMeData({
        field: 'creator',
        value: {
          gender: payload.gender,
          country: payload.country,
          referralCode: payload.code,
          kycId: payload.kyc,
          contractId: payload.contract,
          isApproved: payload.approved,
        },
      })
    );
    return dispatch({ type: SET_NETWORKING_SUCCESS, payload: { selectPath } });
  } catch (error) {
    return dispatch({
      type: SET_NETWORKING_ERROR,
      payload: { selectPath, error },
    });
  }
};

export default fetchMeCreator;
