// getIsCpOnboarding.js
'use strict';
import { createSelector } from '../resource/createCachedSelector.js';

import getJoinProgress from '../selector/getJoinProgress.js';

import { Progress as JoinProgress } from '../resource/joinConstants.js';

/**
 * Select is cp onboarding by
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {boolean} The selected is cp onboarding.
 */
const getIsCpOnboarding = createSelector(
  state => getJoinProgress(state),
  joinProgress => {
    return [
      JoinProgress.OCR_PROCESSING,
      JoinProgress.OCR_RESULT,
      JoinProgress.MANUAL_REVIEW,
      JoinProgress.BECOME_SWAGGER,
    ].includes(joinProgress);
  }
);

export default getIsCpOnboarding;
