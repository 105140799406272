// getInternalLink.js
'use strict';
const isServer = typeof window === 'undefined';
const defaultInternalHosts = isServer ? [] : [location.host];

/**
 * Get is completed link.
 * @param {string} {link} - be checked link.
 * @returns {boolean} will return true if link is valid url.
 */
// TODO: change name to getIsCompletedUrl
export const getIsValidLink = ({ link }) => {
  try {
    new URL(link);

    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Get link is internale link or not.
 * @param {string} {link} - be checked link.
 * @param {array} {[whitelist = []]} - these hosts should be internal also.
 * @returns {boolean} will return true if link is internal link.
 */
export const getIsInternalLink = ({ link, whitelist = [] }) => {
  const isCompletedUrl = getIsValidLink({ link });

  if (!isCompletedUrl) {
    return true;
  }

  const url = new URL(link);

  return [...defaultInternalHosts, ...whitelist].includes(url.host);
};

/**
 * Get should render link or not.
 * @param {string} {link} - be compared link.
 * @returns {boolean} should return false if pathname, query and hash is same.
 */
export const getShouldRenderLink = ({ link }) => {
  return isServer
    ? false
    : `${location?.pathname}${location?.search}${location?.hash}` !== link;
};

/**
 * Get internal link
 * @param {string} {link} - be checked link.
 * @param {array} {[whitelist = []]} - these hosts should be internal also.
 * @returns {string|null} if link match internal link rule, will return internal pathname, otherwise return null.
 */
export const getInternalLink = ({ link, whitelist = [] }) => {
  const isValidLink = getIsValidLink({ link });

  if (!isValidLink) {
    return null;
  }

  const isInternalLink = getIsInternalLink({
    link,
    whitelist,
  });
  const url = new URL(link);

  if (isInternalLink) {
    return `${url.pathname}${url.search}${url.hash}`;
  }

  return null;
};

export default getInternalLink;

const getIsQueryOnly = ({ link }) => /^\?/.test(link);

/**
 * Convert relative link
 * @param {string} {link} - link.
 * @param {string} {[currentPathname = '/']} - current pathname.
 * @param {string} {[currentSearch = '']} - current search.
 * @returns {null|string} return converted absolute pathname (include search and hash also).
 */
export const convertRelativeLink = ({
  link,
  currentPathname = '/',
  currentSearch = '',
}) => {
  if (!link) {
    return null;
  }

  const isLinkWithDomain = getIsValidLink({ link });

  if (isLinkWithDomain) {
    return null;
  }

  const currentSearchParams = new URLSearchParams(currentSearch);
  const notificationURL = new URL(link, 'https://swag.live');
  const notificationSearchParams = notificationURL.searchParams;

  const isCurrentAtHome = currentPathname === '/';
  const isQueryOnly = getIsQueryOnly({ link });

  const mergedURL = new URL(
    isQueryOnly ? '' : link,
    `https://swag.live${
      isCurrentAtHome
        ? ''
        : isQueryOnly
          ? currentPathname
          : `${currentPathname}/`
    }`
  );

  [...currentSearchParams.keys()].forEach(key => {
    mergedURL.searchParams.set(key, currentSearchParams.get(key));
  });
  [...notificationSearchParams.keys()].forEach(key => {
    mergedURL.searchParams.set(key, notificationSearchParams.get(key));
  });

  return `${mergedURL.pathname}${mergedURL.search}${mergedURL.hash}`;
};
